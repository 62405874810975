import Meta from '../components/Meta'
import { Link } from 'react-router-dom'
import { useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'
import { useSelector, useDispatch } from 'react-redux'
import { changeState } from '../store/slices/firstAnimationSlice'

gsap.registerPlugin()
gsap.registerPlugin(useGSAP)
gsap.registerPlugin(ScrollTrigger)

const Home = () => {
  const { isPlay } = useSelector((state) => state.fisrtAnimation)
  const container = useRef()
  const line = useRef()
  const meaning = useRef()
  const promise = useRef()
  const info = useRef()
  const dispatch = useDispatch()

  useGSAP(
    () => {
      if (!isPlay) {
        gsap.from('.box', {
          scale: 0.75,
          opacity: 0,
          ease: 'power1.out',
          stagger: 0.4,
        })
        gsap.from('.title', {
          y: '70px',
          opacity: 0,
          ease: 'power1.out',
          duration: 0.7,
          delay: 3.2,
        })
      }
      dispatch(changeState(true))
    },
    { scope: container }
  )

  useGSAP(
    () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.meaning-title',
          start: 'end 60%',
          // markers: true,
        },
      })

      if (!isPlay) {
        tl.from('.meaning-title', {
          y: 100,
          duration: 1,
          autoAlpha: 0,
        })
        tl.from('.meaning-list li', {
          y: 100,
          autoAlpha: 0,
          stagger: 0.3,
        })
      }
    },
    { scope: meaning }
  )

  useGSAP(
    () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.promise',
          start: 'start 50%',
          // markers: true,
        },
      })

      if (!isPlay) {
        tl.from('.promise-bg', {
          opacity: 0,
          duration: 1,
          delay: 1.5,
        })
        tl.from('.promise-item', {
          y: 100,
          autoAlpha: 0,
          stagger: 0.3,
          duration: 0.75,
        })
      }
    },
    { scope: promise }
  )

  useGSAP(() => {
    gsap.from('.line', {
      y: '70px',
      opacity: 0,
      ease: 'power1.out',
      duration: 0.5,
      delay: isPlay ? 0.5 : 4,
    })
    gsap.to('.line', {
      ease: 'power1.out',
      x: 100,
      duration: 0.5,
      scrollTrigger: {
        trigger: '.footer',
        start: 'top 60%',
        end: '10% 40%',
        toggleActions: 'play none none reverse',
        // markers: true,
      },
    })
  })

  return (
    <>
      <Meta title="株式会社815" />
      <main
        ref={container}
        className="w-full flex-1 flex flex-col px-[1rem] mb-20 lg:mb-28"
      >
        <div className="calc-height grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 auto-rows-fr gap-5 w-full pb-[1rem] md:pb-[2rem] relative ">
          <div className="col-span-3 md:col-span-2 md:row-span-2">
            <div className="title flex flex-col sm:pl-5 justify-center h-full">
              <h1 className="text-3xl/relaxed lg:text-[2rem]/relaxed xl:text-[2.6rem]/relaxed 2xl:text-5xl/relaxed">
                ⼦どもにも、⼤⼈にも、 <br />
                楽しく・明るい未来を！
              </h1>
              <p className="text-base/relaxed md:text-lg/relaxed xl:text-2xl/relaxed mt-3 md:mt-5">
                私たちはそんな未来を実現するために <br />
                挑戦する人をサポートします
              </p>
            </div>
          </div>
          <div className="box -order-2 md:order-none block col-span-2 rounded-lg bg-top-seminar bg-cover"></div>
          <div className="box -order-1 md:order-none block bg-secondary rounded-lg"></div>
          <div className="box bg-primary rounded-lg"></div>
          <div className="box col-span-2 row-span-1 md:row-span-3 rounded-lg bg-top-woman bg-cover bg-center"></div>
          <div className="box col-span-2 row-span-1 md:row-span-2 rounded-lg bg-top-children bg-cover bg-center"></div>
          <div className="box rounded-lg bg-top-children-circle bg-cover bg-center hidden md:block"></div>
          <div className="box bg-accent rounded-lg"></div>
        </div>
      </main>

      <div ref={meaning} className="mb-20 lg:mb-28 w-full">
        <div className="container meaning-title w-full flex flex-col items-center">
          <h4 className="border border-black py-2 px-5 text-lg mb-10">
            815に込められた思い
          </h4>
          <h2 className="text-2xl md:text-3xl mb-10">
            数字だっていろんな形がある
          </h2>
          <p className="text-base leading-7 md:text-center md:leading-8 mb-10">
            「８」のように丸かったり「１」のように真っ直ぐで、「５」のように丸くて真っ直ぐなものも。
            <br />
            どんな形であれ、それは「⾃分」。⼈はそれぞれその性格が異なるように、
            <br className="hidden md:block" />
            数字にもそれぞれに象徴する意味が数学的、歴史的、⽂学的など、いろいろな意味を持っている。
          </p>
          <ul className="meaning-list flex flex-col md:flex-row gap-5 w-full">
            <li className="bg-[#F7F4F0] flex-1 rounded-lg flex flex-col items-center p-7 lg:p-10">
              <img
                className="w-20 md:w-24 mb-5"
                src="/images/8.png"
                alt="815の8"
              />
              <h5 className="bg-white py-2 px-5 rounded-full font-bold tracking-wider text-center">
                ⾏動⼒と情熱
              </h5>
            </li>
            <li className="bg-[#F7F4F0] flex-1 rounded-lg flex flex-col items-center p-7 lg:p-10">
              <img
                className="w-20 md:w-24 mb-5"
                src="/images/1.png"
                alt="815の1"
              />
              <h5 className="bg-white py-2 px-5 rounded-full font-bold tracking-wider text-center">
                勇気と
                <br className="hidden md:inline-block lg:hidden" />
                リーダーシップ
              </h5>
            </li>
            <li className="bg-[#F7F4F0] flex-1 rounded-lg flex flex-col items-center p-7 lg:p-10">
              <img
                className="w-20 md:w-24 mb-5"
                src="/images/5.png"
                alt="815の5"
              />
              <h5 className="bg-white py-2 px-5 rounded-full font-bold tracking-wider text-center">
                ⾃由と好奇⼼
              </h5>
            </li>
          </ul>
        </div>
      </div>

      <div ref={promise} className="w-full mb-20 lg:mb-28">
        <div className="promise bg-top-promise bg-cover bg-center">
          <div className="promise-bg w-full backdrop-blur-3xl bg-white/70 py-16 lg:py-36">
            <div className="container flex flex-col items-center">
              <h4 className="promise-item border border-black py-2 px-16 text-lg mb-10 bg-white">
                815の約束
              </h4>
              <h2 className="promise-item text-2xl md:text-3xl mb-10">
                希望に満ちた未来を提供します
              </h2>
              <p className="promise-item text-base leading-7 md:text-center md:leading-10">
                私たちは、私たちが関わるすべての⼈たちに、⼼からの安⼼と安全、
                <br />
                そして希望に満ちた未来を提供することをモットーにしています。
                <br />
                そのために、私たちは誰にとっても、活気に満ちて楽しく、癒され、⼼地良く、あたたかく、
                <br />
                そして⼒を与えられるスペースを提供します。
                <br />
                私たちは、お客さまにワンランク上の学びと成⻑を⾃社のサービスとして提供すべく、
                <br />
                ⽇々学びを継続し、未来を創るプロとして
                <br />
                真⼼をこめてサービスを提供します。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div ref={info} className="w-full">
        <div className="container meaning-title w-full flex flex-col items-center mb-20 lg:mb-28">
          <h4 className="border border-black py-2 px-5 text-lg mb-10">
            インフォメーション
          </h4>
          <h2 className="text-2xl md:text-3xl mb-10">お知らせ</h2>
          <ul className="w-full grid grid-cols-1 md:grid-cols-2 gap-10 lg:grid-cols-3 xl:gap-20">
            <li className="flex flex-col justify-between">
              <div>
                <img
                  className="mb-7 rounded"
                  src="/images/campaign_banner_1213.jpg"
                  alt=""
                />
                <p className="text-sm">2024.12.13</p>
                <h5 className="mb-4 text-lg">2025年4月生募集スタート！</h5>
                <p className="text-sm/6 mb-5">無料体験、始まってます</p>
              </div>
              <Link to="/ecc-junior">
                <button className="border border-black p-1 text-sm w-full hover:bg-neutral-100">
                  詳細はこちら
                </button>
              </Link>
            </li>
            <li className="flex flex-col justify-between">
              <div>
                <img
                  className="mb-7 rounded"
                  src="/images/campaign_banner_1130.jpg"
                  alt=""
                />
                <p className="text-sm">2024.11.30</p>
                <h5 className="mb-4 text-lg">ECC中学生英語暗誦大会 全国大会</h5>
                <p className="text-sm/6 mb-5">
                  生徒さんが大阪で行われた全国大会に出場しました！
                </p>
              </div>
              <Link
                to="https://www.instagram.com/eccmakuharibaytown?igsh=bWgxM2hkc3FqOHBu&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="border border-black p-1 text-sm w-full hover:bg-neutral-100">
                  詳細はこちら
                </button>
              </Link>
            </li>
            <li className="flex flex-col justify-between">
              <div>
                <img
                  className="mb-7 rounded"
                  src="/images/campaign_banner_1217.jpg"
                  alt="Christmas Market 2024"
                />
                <p className="text-sm">2024.12.17</p>
                <h5 className="mb-4 text-lg">Christmas Market 2024</h5>
                <p className="text-sm/6 mb-5">
                  12月8日の教室は、にぎやかなクリスマスイベントで大盛況でした！生徒さんボランティアも大活躍！！
                </p>
              </div>
              <Link
                to="https://reserva.be/815"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="border border-black p-1 text-sm w-full hover:bg-neutral-100">
                  ご予約はこちら
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div
        ref={line}
        className="line fixed bottom-8 right-3 md:bottom-10 md:right-10 cursor-pointer z-10"
      >
        <Link
          to="https://lin.ee/loPdOzn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
            alt="友だち追加"
            className="line w-28 lg:w-36"
          />
        </Link>
      </div>
    </>
  )
}

export default Home
